<div class="flex justify-end gap-4">
  <a
    *ngIf="currentPage > 1"
    [routerLink]="baseURL" [queryParams]="getQueryObjectForPage(this.currentPage - 1)"
    id="pagination_prev_arrow"
    class="flex justify-center bg-transparent text-white py-2 px-4 w-10 rounded-lg font-bold hover:bg-emerald-400"
    data-ph-capture-attribute-sc-event="server listing paginate"
    data-ph-capture-attribute-pagination-target="prev">
    <lucide-icon name="chevron-left"></lucide-icon>
  </a>
  <ng-container *ngIf="currentPage > 2">
    <a
    [routerLink]="baseURL" [queryParams]="getQueryObjectForPage(1)"
      class="text-white py-2 px-4 rounded-lg font-bold hover:bg-emerald-400"
      data-ph-capture-attribute-sc-event="server listing paginate"
      data-ph-capture-attribute-pagination-target="first">
      1
    </a>
    <button *ngIf="currentPage > 3"
      class="text-white py-2 px-4 rounded-lg font-bold">
      ...
    </button>
  </ng-container>
  <a
    class="text-white py-2 px-4 rounded-lg font-bold hover:bg-emerald-400"
    [ngClass]="{
      'bg-emerald-400': item === this.currentPage,
      'bg-transparent': item !== this.currentPage
    }"
    *ngFor="let item of possiblePages(); let i = index"
    data-ph-capture-attribute-sc-event="server listing paginate"
    [attr.data-ph-capture-attribute-pagination-target]="i"
    [routerLink]="baseURL" [queryParams]="getQueryObjectForPage(item)">
    {{item}}
  </a>
  <ng-container *ngIf="totalPages > (currentPage + 3)">
    <button
      class="text-white py-2 px-4 rounded-lg font-bold">
      ...
    </button>
    <a
      [routerLink]="baseURL" [queryParams]="getQueryObjectForPage(totalPages)"
      class="text-white py-2 px-4 rounded-lg font-bold hover:bg-emerald-400"
      data-ph-capture-attribute-sc-event="server listing paginate"
      data-ph-capture-attribute-pagination-target="last">
      {{totalPages}}
    </a>
  </ng-container>
  <a
    *ngIf="currentPage < totalPages"
    [routerLink]="baseURL" [queryParams]="getQueryObjectForPage(this.currentPage + 1)"
    id="pagination_next_arrow"
    class="flex justify-center bg-transparent text-white py-2 px-4 w-10 rounded-lg font-bold hover:bg-emerald-400"
    data-ph-capture-attribute-sc-event="server listing paginate"
    data-ph-capture-attribute-pagination-target="next">
    <lucide-icon name="chevron-right"></lucide-icon>
  </a>
</div>
